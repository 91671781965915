
@import '/src/styles/global.scss';

.main {
    position: relative;
    background-color: white;
    padding: var(--gutter);
    box-shadow: var(--box-shadow);
    width: 100%;
    height: $title-bar-height;
    // background-color: blue;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .mainMenuBtn {

    }

    .sessionMenuBtn {

    }

    .logo {
        cursor: pointer;
        color: $color-blue;
        font-size: 45px;
        font-weight: bold;
        font-family: 'Times New Roman', serif;
        display: flex;
        align-items: center;
        // background-color: green;

        &:hover {
            color: $color-blue;
            color: darken($color: $color-blue, $amount: 10%)
        }
    }

    .mainMenu, .sessionMenu {
        position: absolute;
        background-color: white;
        z-index: 1;

        top: $title-bar-height;
        box-shadow: var(--box-shadow);

        h1 {
            text-align: center;
            font-size: 20px;
            font-weight: bold;
        }
    }

    .mainMenu {
        left: 0;
        width: $main-menu-width;
        // bottom: 0;
    }

    .sessionMenu {
        right: 0;
        max-width: $main-menu-width;
        width: $main-menu-width;
    }
    
}