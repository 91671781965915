@import '../../../styles/variables.scss';

.main {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;


    .buttons {
        display: flex;
        justify-content: space-between;
        margin: var(--gutter) 0;
        width: 100%;
    }
    
    .button {
        border: 1px solid white;
        color: white;
        background-color: $color-blue-dark;
        padding: var(--gutter-sm) var(--gutter);

        &:hover {
            background-color: $color-blue-light;
        }        
    }

    .horizontalLabelItem {
        display: flex;
        align-items: center;
        // margin: var(--gutter) 0;
        margin: 0;
    }

    .controls {
        width: 100%;
        margin: var(--gutter) 0;
    }

    .transcribing {
        background-color: $color-blue-dark;
    }

    .text {
        color: white;
        font-size: 1.1rem;
        text-align: center;
        margin: var(--gutter-md) 0;
    }
}