// @import '../styles/variables.scss';
@import './variables.scss';

.backgroundImage {
    background-image: url('../../public/assets/images/forestDeer.jpg');
    background-image: url('../../public/assets/images/tardisCoaster.jpg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    height: 100vh;
    width: 100vw;
}

.container {

    @keyframes App-logo-spin {
        from {
            transform: rotate(0deg);
        }

        to {
            transform: rotate(360deg);
        }
    }

    .main {
        position: absolute;
        height: calc(100vh - $title-bar-height);
        width: 100%;
        overflow: hidden;
        // background-color: green;
    }

}