// @import '../../styles/global.scss';
@import '/src/styles/global.scss';

$phrase-background-color-hover: $color-blue-darker;

@keyframes pulse {

    0%,
    100% {
        // transform: scale(1);
        opacity: 1;
    }

    50% {
        // transform: scale(1.05);
        opacity: 0.5;
    }
}

.pending {
    background-color: $color-orange-dark !important;

    &:hover {
        background-color: $color-orange-darker !important;
        animation: pulse 2s infinite;
        color: white;
    }
    animation: pulse 2s infinite;
}

.error {
    background-color: $color-red-dark !important;

    &:hover {
        background-color: $color-red-darker !important;
        // animation: pulse 2s infinite;
        color: white;
    }
    // animation: pulse 2s infinite;
}

.main {
    position: relative;
    box-shadow: var(--box-shadow);
    margin-bottom: var(--gutter-sm);
    margin-top: var(--gutter-sm);
    cursor: pointer;

    h2 {
        display: flex;
        align-items: center;
        padding-left: var(--gutter-sm);

        .languageCount {
            font-weight: normal;
        }

    }

    .arrow {
        padding-right: var(--gutter-sm);
    }

    .phrase {

        background-color: #555;
        background-color: $color-blue-dark;

        &:hover {
            background-color: $phrase-background-color-hover;
            color: white;
        }
    }

    .phraseErrored {
        background-color: $color-red-pale;

        &:hover {
            background-color: $color-red-pale-dark;
            animation: pulse 2s infinite;
            color: white;
        }
        animation: pulse 2s infinite;
    }

    .languageSection {

        .language {
            display: flex;
            align-items: center;
            padding-left: var(--gutter);
            background-color: $color-blue-pale;

            &:hover {
                background-color: $color-blue-pale-dark;
                color: white;
            }

        }

        .breakdownSection {
            cursor: default;

            .breakdown {
                padding-left: var(--gutter-md);
                background-color: $color-blue-pale-lighter;
            }
        }

    }

    .selectedPhrase {
        background-color: $color-blue-darker;
    }

    .selectedLanguage {
        background-color: $color-blue-pale-darker !important;
    }

    .speakableText {

        display: inline-flex;
        align-items: center;
        padding: var(--gutter-xs);
        border-radius: var(--border-radius-sm);
        max-width: 90%;

        .speaker {
            border-radius: 50%;
            height: 25px;
            width: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            &:hover {
                background-color: $color-orange-light;
                color: white;

                .speakerIcon {
                    color: white;
                }
            }

            .speakerIcon {
                color: $color-orange-light;
                margin-right: 4px;
                height: 15px;
                width: 15px;
                margin-left: var(--gutter-xs);
            }
        }

    }

    .deleteIcon {
        width: 20px;
        height: 20px;
        color: transparent;
        position: absolute;
        right: var(--gutter-sm);

        &:hover {
            color: #444
        }
    }

    .odd {
        background-color: #555;
    }

    .even {
        background-color: #666;
    }

    .oddDark {
        background-color: #333;
    }

    .evenDark {
        background-color: #444;
    }

    .stats {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        padding-left: var(--gutter-xl);
        padding-right: var(--gutter-xl);
        background-color: $phrase-background-color-hover;
        font-size: 0.7rem;
        padding-top: var(--gutter-xs);
        padding-bottom: var(--gutter-xs);
        font-weight: normal;
        cursor: default;

        span {
            white-space: nowrap;
        }

        strong {
            color: $color-yellow-light;
        }
    }

    @media screen and (max-width: $mobile-width-breakpoint) {
        .deleteIcon {
            color: #444;
            display: block;

            &:hover {
                display: block;
                color: $color-red-darker;
            }
        }
    }

}