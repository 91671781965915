@import 'variables.scss';

// styles.scss
.btn {
    @apply text-white font-bold py-2 px-4 rounded;

    box-shadow: var(--box-shadow);

    background-color: $color-blue-dark;

    &:hover {
        background-color: $color-blue-darker;
    }
}

.input {
    @apply text-sm placeholder-gray-500 border border-gray-300 rounded-md py-2 px-4 leading-tight focus:outline-none focus:border-blue-500;
}

.label {
    @apply block text-gray-700 text-sm font-bold;
}

form {

    input {
        @apply p-1;
        @apply text-base;
        border-radius: var(--border-radius-sm);
    }

}