$button-size: 75px;

$cool-blue: #088fdd;

$playbackBorderRadius: $button-size / 2;

.main {
    width: 100%;

    .controls {
        width: 100%;
        // background-color: #222; /* Dark background for contrast */
        padding: 10px;
        // border-radius: $playbackBorderRadius;
        display: flex;
        justify-content: space-between;
        gap: 10px;

        // .startButton:hover,
        // .pauseButton:hover,
        // .stopButton:hover,
        // .saveButton:hover {
        //     // border: 3px solid #fff;
        // }

        .startButton,
        .pauseButton,
        .stopButton,
        .saveButton {
            height: $button-size;
            border: none;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: $playbackBorderRadius;
            /* Make the button round */
        }

        .startButton svg,
        .pauseButton svg,
        .stopButton svg,
        .saveButton svg {
            width: 2rem;
            height: 2rem;
        }

        .startButton,
        .pauseButton {
            width: calc(100% - 10px - #{$button-size});
        }

        .startButton {
            background-color: #4CAF50;
        }

        .startButton:hover,
        .startButton:active {
            background-color: #66BB6A;
        }

        .pauseButton {
            background-color: #FFEB3B;
        }

        .pauseButton:hover,
        .pauseButton:active {
            background-color: #FDD835;
        }

        .stopButton {
            background-color: #F44336;
            width: $button-size;
        }

        .saveButton {
            background-color: $cool-blue;
            width: $button-size;
        }

        .stopButton:hover,
        .stopButton:active {
            background-color: #EF5350;
        }

    }

}

.hasAudio {
    background-color: #aaa !important;
    /* Dark background for contrast */
}

.started {
    background-color: #555;
    /* Dark background for contrast */
}

// .stopped {
//     // background-color: #000;
//     /* Dark background for contrast */
// }

// dim the stop button when stopped
.stopped .stopButton,
.stopped .stopButton:hover {
    opacity: 0.5;
    border: 3px solid transparent;
}

// dim the start and pause buttons when not stopped
:not(.stopped)>.startButton,
:not(.stopped)>.pauseButton {
    opacity: 0.5;
}