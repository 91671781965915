@use "sass:map";
@import 'variables.scss';

$config: (
    "container-width": 1000px,
    "color": (
        "background": #f0f0f0,
        "input-background": #fff,
        "submit-button": #007bff,
        "submit-button-hover": #0056b3,
        "text": #333,
    ),
);

$container-width: map.get($config, "container-width");

.assistantContainer {
    background-color: map.get($config, "color", "background");
    max-width: map.get($config, "container-width");
    padding: var(--gutter);
    margin: var(--gutter-lg) auto;
    border-radius: var(--border-radius);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    .assistantForm {
        display: flex;
        flex-direction: column;
    }

    .inputGroup {
        margin-bottom: var(--gutter);
    }

    .textArea {
        width: 100%;
        padding: var(--gutter-sm);
        margin-top: var(--gutter-sm);
        border: 1px solid #ccc;
        border-radius: var(--border-radius-sm);
        background-color: map.get($config, "color", "input-background");
        resize: vertical;
    }

    .submitButton {
        padding: var(--gutter-sm) var(--gutter);
        background-color: map.get($config, "color", "submit-button");
        border: none;
        border-radius: var(--border-radius);
        color: #fff;
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover {
            background-color: map.get($config, "color", "submit-button-hover");
        }
    }

    .submissionsList {
        margin-top: var(--gutter-lg);
        h3 {
            margin-bottom: var(--gutter-sm);
        }
        ul {
            list-style: none;
            padding: 0;
        }
        li {
            background-color: map.get($config, "color", "input-background");
            padding: var(--gutter-sm);
            margin-bottom: var(--gutter-sm);
            border-radius: var(--border-radius-sm);
        }
    }
}
