

$color-blue: #3b82f6;
$color-blue-light: lighten($color: $color-blue, $amount: 5%);
$color-blue-lighter: lighten($color: $color-blue, $amount: 10%);
$color-blue-dark: darken($color: $color-blue, $amount: 5%);
$color-blue-darker: darken($color: $color-blue, $amount: 10%);
$color-blue-pale: #3b82f6a0;
$color-blue-pale-light: lighten($color: $color-blue-pale, $amount: 5%);
$color-blue-pale-lighter: lighten($color: $color-blue-pale, $amount: 10%);
$color-blue-pale-dark: darken($color: $color-blue-pale, $amount: 5%);
$color-blue-pale-darker: darken($color: $color-blue-pale, $amount: 10%);

// cool green

$color-orange: #dd9900;
$color-orange-light: lighten($color: $color-orange, $amount: 5%);
$color-orange-lighter: lighten($color: $color-orange, $amount: 10%);
$color-orange-dark: darken($color: $color-orange, $amount: 5%);
$color-orange-darker: darken($color: $color-orange, $amount: 10%);
$color-orange-pale: #dd9900a0;
$color-orange-pale-light: lighten($color: $color-orange-pale, $amount: 5%);
$color-orange-pale-dark: darken($color: $color-orange-pale, $amount: 5%);
$color-orange-pale-lighter: lighten($color: $color-orange-pale, $amount: 10%);
$color-orange-pale-darker: darken($color: $color-orange-pale, $amount: 10%);

$color-green: #00aa44; 
$color-green-light: lighten($color: $color-green, $amount: 5%);
$color-green-lighter: lighten($color: $color-green, $amount: 10%);
$color-green-dark: darken($color: $color-green, $amount: 5%);
$color-green-darker: darken($color: $color-green, $amount: 10%);
$color-green-pale: #00aa44a0;
$color-green-pale-light: lighten($color: $color-green-pale, $amount: 5%);
$color-green-pale-lighter: lighten($color: $color-green-pale, $amount: 10%);
$color-green-pale-dark: darken($color: $color-green-pale, $amount: 5%);
$color-green-pale-darker: darken($color: $color-green-pale, $amount: 10%);

$color-red: #dd0000;
$color-red-light: lighten($color: $color-red, $amount: 5%);
$color-red-lighter: lighten($color: $color-red, $amount: 10%);
$color-red-dark: darken($color: $color-red, $amount: 5%);
$color-red-darker: darken($color: $color-red, $amount: 10%);
$color-red-pale: #dd0000a0;
$color-red-pale-light: lighten($color: $color-red-pale, $amount: 5%);
$color-red-pale-lighter: lighten($color: $color-red-pale, $amount: 10%);
$color-red-pale-dark: darken($color: $color-red-pale, $amount: 5%);
$color-red-pale-darker: darken($color: $color-red-pale, $amount: 10%);

$color-purple: #8000b0;
$color-purple-light: lighten($color: $color-purple, $amount: 5%);
$color-purple-lighter: lighten($color: $color-purple, $amount: 10%);
$color-purple-dark: darken($color: $color-purple, $amount: 5%);
$color-purple-darker: darken($color: $color-purple, $amount: 10%);
$color-purple-pale: #8000b0a0;
$color-purple-pale-light: lighten($color: $color-purple-pale, $amount: 5%);
$color-purple-pale-lighter: lighten($color: $color-purple-pale, $amount: 10%);
$color-purple-pale-dark: darken($color: $color-purple-pale, $amount: 5%);
$color-purple-pale-darker: darken($color: $color-purple-pale, $amount: 10%);

$color-yellow: #dddd00;
$color-yellow-light: lighten($color: $color-yellow, $amount: 5%);
$color-yellow-lighter: lighten($color: $color-yellow, $amount: 10%);
$color-yellow-dark: darken($color: $color-yellow, $amount: 5%);
$color-yellow-darker: darken($color: $color-yellow, $amount: 10%);
$color-yellow-pale: #dddd00a0;
$color-yellow-pale-light: lighten($color: $color-yellow-pale, $amount: 5%);
$color-yellow-pale-lighter: lighten($color: $color-yellow-pale, $amount: 10%);
$color-yellow-pale-dark: darken($color: $color-yellow-pale, $amount: 5%);
$color-yellow-pale-darker: darken($color: $color-yellow-pale, $amount: 10%);




$main-menu-width: 200px;
$title-bar-height: 60px;

$mobile-width-breakpoint: 900px;