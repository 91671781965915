
.form {
    display: flex;
    flex-direction: column;

    .label {
        display: flex;
        align-items: center;
    }

    .input {
        border-radius: 0;
    }

    :global(.btn) {
        border-radius: 0;
    }
}
