// styles.scss
@import './tailwind.css';
@import './variables.scss';

:root {

    // I don't think it works like this
    --color-blue: $color-blue;
    --title-bar-height: $title-bar-height;

    --font-size: 18px;
    
    /* Gutters */
    --gutter-xxs: 2px;
    --gutter-xs: 4px;
    --gutter-sm: 8px;
    --gutter: 16px;
    --gutter-md: 24px;
    --gutter-lg: 32px;
    --gutter-xl: 48px;
    --gutter-xxl: 64px;

    /* Border Radii */
    --border-radius-xs: 4px;
    --border-radius-sm: 8px;
    --border-radius: 16px;
    --border-radius-md: 24px;
    --border-radius-lg: 32px;

    --box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

    --box-shadow-right: 3px 0px 10px rgba(0, 0, 0, 0.5);
}

body {
    font-family: sans-serif;
    font-size: var(--font-size);
    margin: 0;
    padding: 0;
}
/* Width and height of the entire scrollbar */
::-webkit-scrollbar {
    width: 12px;
    height: 12px;
}

/* Track of the scrollbar */
::-webkit-scrollbar-track {
    background: #f0f0f0; /* color of the tracking area */
    border-radius: 10px;
}

/* Handle of the scrollbar */
::-webkit-scrollbar-thumb {
    background-color: #888; /* color of the scroll thumb */
    border-radius: 10px; /* roundness of the scroll thumb */
    border: 3px solid #f0f0f0; /* creates padding around scroll thumb */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555; /* color when hover */
}
