@use "sass:map";
@import '../../styles/variables.scss';

$config: (
    "main-width": 1000px,
    "color": ("border": #000,
        "secondary": #333,
        "api-connecting": blue,
        "api-connected": #0a0,
        "api-disconnected": #b00,
    ),
);

$main-width: map.get($config, "main-width");

.main {
    position: relative;
    background-color: map.get($config, "color", "secondary");
    max-width: map.get($config, "main-width");
    border-radius: var(--border-radius);
    padding: var(--gutter);
    margin: var(--gutter-lg) auto;
    height: calc(100vh - $title-bar-height * 2);
    overflow: auto;

    color: white;

    .modelDropdown {
        position: absolute;
        width: 150px;
        right: 0;
        top: 50px;
        margin: var(--gutter);
        font-size: small;
    }

    .header {
        color: $color-blue-dark;
        font-weight: bold;
    }

    h1 {
        text-align: center;
        font-size: 30px;
        font-weight: bold;
    }

    h2 {
        // text-align: center;
        font-size: 20px;
        font-weight: bold;
    }

}

.translateTextArea {
    color: white;
    background-color: #444;
}

.languageOptionButtons {
    display: flex;
    justify-content: space-between;
    margin: var(--gutter) 0;
}

@media (max-width: $mobile-width-breakpoint) {
    .main {
        width: 100%;
        border-radius: 0;
        margin: 0;
        height: calc(100vh - $title-bar-height);
    }

}